@if (reader) {
  <div class="space-y-8" @fadeIn>
    <div class="space-y-3">
      <h3>{{ reader.title }}</h3>
      @for (_section of reader.sections; track _section) {
        <p class="text-sm text-black leading-3.6" [innerHtml]="_section"></p>
      }
    </div>
    @for (_section of reader.list; track _section) {
      <div class="space-y-3">
        <h3>{{ _section.title }}</h3>
        <p class="text-sm text-black leading-3.6" [innerHTML]="_section.content"></p>
      </div>
    }
  </div>
}
