import { Component, Inject, OnInit, Optional, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CarouselComponent } from '@swan/lib/components';
import { InventoryPolicy, Product, ProductImage, ProductVariant, Type } from '@swan/lib/domain';
import { getStoreLink, openApp } from '../../shared/layout/layout.component';
import { ConfigService} from "@yukawa/chain-base-angular-client";
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { Response } from 'express';
import { RESPONSE } from 'share/src/app/core/services/server/server.token';
import { EmojiService } from '@yukawa/chain-base-angular-comp/shared';
import sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ProductViewComponent implements OnInit {
  @ViewChild('carousel')carousel!: CarouselComponent

  configService: ConfigService = inject(ConfigService);
  emojiService: EmojiService = inject(EmojiService);
  _platformId: Object = inject(PLATFORM_ID);

  product:Product;
  _selectedVariant?: ProductVariant;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private meta: Meta,
    private title: Title,
    @Optional() @Inject(RESPONSE) private response: Response // is NOT injected on DEV (using `ng serve`) build app and run with node /.../server/server.mjs
  ) {
    this.product = _route.snapshot.data['product'];
    if(!this.product){
      if(isPlatformServer(this._platformId) && this.response){
        this.response.redirect(301, '/404');
        this.response.end();
      }else{
        this._router.navigate(['/404']);
        return;
      }
    }else{
      this.title.setTitle( `${this.emojiService.colonsToNative(this.product.titleOverride || this.product.title || '')} - SWAN Beauty Community`);
      this.meta.addTags([
        {property: 'og:title', content: this.emojiService.colonsToNative(this.product.titleOverride || this.product.title || '')},
        {property: 'og:type', content: 'product'},
        {property: 'og:image', content: ''},
        {property: 'og:url', content: this.configService.getBaseUrl()},
        {name: 'twitter:card', content: 'summary_large_image'},
  
        {property: 'og:description', content: this.emojiService.colonsToNative(this.product.descriptionOverride || this.product.description || '')},
        {property: 'og:site_name', content: 'SWAN Beauty Community'},
        {property: 'twitter:image:alt', content: `${this.product.title} on SWAN`},
      ]);
    }
  }

  get variantImages(): ProductImage[]{
    if(!this.selectedVariant || !this.selectedVariant.images){
      return this.product.images || [];
    }else if(this.selectedVariant.images.length === 0){
      return this.product.images || [];
    }
    return [...this.selectedVariant.images, ...this.product.images ||[] ] || [] ;
  }

  get productDesc(): string{
    return sanitizeHtml(this.product.descriptionOverride || this.product.description || '', {});
  }
  get selectedVariant(): ProductVariant{
    return this._selectedVariant || this.product.variants![0];
  }
  set selectedVariant(variant: ProductVariant){
    this._selectedVariant = variant;
  }

  ngOnInit(): void {
    console.log('ProductViewComponent onInit',this.product);
    if(this.product.variants?.length === 1){
      this.selectedVariant = this.product.variants[0];
    }
  }

  hasColorOptions():boolean{
    return this.product.options?.some(o => o.type === Type.COLOR) || false;
  }
  isAvailable(variant: ProductVariant):boolean{
    return (variant.inventoryQuantity || 0) <= 0 && variant.inventoryPolicy=== InventoryPolicy.DENY
  }
  variantPriceReference(): string{
    return `${this.selectedVariant.unitPrice?.quantityValue} ${this.selectedVariant.unitPrice?.quantityUnit.toLowerCase()} (${this.unitPriceString()} / ${this.selectedVariant.unitPrice.referenceValue} ${this.selectedVariant.unitPrice.referenceUnit.toLowerCase()})`;
  }

  unitPriceString():string{
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.unitPrice() || 0);
  }

  unitPrice():number|null{
    const ratio = this.getRatio();
    if(!ratio){
      return null;
    }
    return ratio * (this.selectedVariant.price!) / (this.selectedVariant.unitPrice.quantityValue / this.selectedVariant.unitPrice.referenceValue);
  }

  getRatio():number|null{
    if(!this.selectedVariant.unitPrice){
      return null;
    }
    if(this.selectedVariant.unitPrice.quantityUnit === this.selectedVariant.unitPrice.referenceUnit){
      return 1.0
    }
    const fromUnit = this.selectedVariant.unitPrice.quantityUnit;
    const toUnit = this.selectedVariant.unitPrice.referenceUnit;
    // Define conversion factors for volume and weight
    const conversionRates:Map<string,number> = new Map([
        ["L", 1],                // base unit for volume
        ["ML", 1000],        // 1 liter = 1000 milliliters
        ["CL", 100],         // 1 liter = 100 centiliters
        ["KG", 1],             // base unit for weight
        ["G", 1000],              // 1 kilogram = 1000 grams
        ["MG", 1000000]       // 1 kilogram = 1,000,000 milligrams
    ]);

    // Check if the units are valid and compatible
    const isVolumeUnit = ["L", "ML", "CL"].includes(fromUnit) && ["L", "ML", "CL"].includes(toUnit);
    const isWeightUnit = ["KG", "G", "MG"].includes(fromUnit) && ["KG", "G", "MG"].includes(toUnit);

    if (!isVolumeUnit && !isWeightUnit) {
        console.error("Incompatible or invalid units. Please convert between compatible volume or weight units only.");
        return null;
    }

    return conversionRates.get(fromUnit)! / conversionRates.get(toUnit)! ;
  }

    getImageUrl(): string {
        //https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+profile.imageId
        return this.configService.getBaseUrl() + '/profile-service/image/download?imageId=' + this.product?.vendor?.profile?.imageId;
    }

  getStoreLink = openApp
}
