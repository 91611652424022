import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Injector, OnDestroy, OnInit, Optional, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Content, PlaybackToken } from '@swan/lib/content';
import { ContentData } from '../content.resolver';
import { getStoreLink, openApp } from '../../shared/layout/layout.component';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { Response } from 'express';
import { RESPONSE } from 'share/src/app/core/services/server/server.token';
import { EmojiService } from '@yukawa/chain-base-angular-comp/shared';

@Component({
  selector: 'app-content-player',
  templateUrl: './content-player.component.html',
  styleUrls: ['./content-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('muxVideo') readonly muxVideo: ElementRef<HTMLVideoElement> = null as never;
  @ViewChild('backdrop') readonly backdrop: ElementRef<HTMLCanvasElement> = null as never;
  @ViewChild('storelink') readonly storelink: ElementRef<HTMLAnchorElement> = null as never;

  configService: ConfigService = inject(ConfigService);
  emojiService: EmojiService = inject(EmojiService);
  _platformId: Object = inject(PLATFORM_ID);

  playback!: PlaybackToken;
  content!: Content;
  id : string = '';
  token : string = '';
  video: any|null = null;
  interval?:number;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private ref: ChangeDetectorRef,
    private meta: Meta,
    private title: Title,
    @Optional() @Inject(RESPONSE) private response: Response // is NOT injected on DEV (using `ng serve`) build app and run with node /.../server/server.mjs
    ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data:Data) => {
      const contendData = data["data"] as ContentData;
      console.debug('comp',contendData);
      
      if (contendData.playback) {
        this.playback = contendData.playback;
        this.id = this.playback.videoSrc.split("?")[0]
        this.token = this.playback.videoSrc.split("?")[1].substring(6);
      }
      if(contendData.content){
        this.content = contendData.content;
      }
      if(!this.content){
        if(isPlatformServer(this._platformId) && this.response){
          this.response.redirect(301, '/404');
          this.response.end();
        }else{
          this._router.navigate(['/404']);
        }
      }
      this.title.setTitle( `${this.emojiService.colonsToNative(this.content.info.name || '')} - SWAN Beauty Community`);
      this.meta.addTags([
        {property: 'og:title', content: this.emojiService.colonsToNative(this.content.info.name || '')},
        {property: 'og:type', content: 'video'},
        {property: 'og:image', content: this.getImageUrl()},
        {property: 'og:url', content: this.configService.getBaseUrl()},
        {name: 'twitter:card', content: 'summary_large_image'},
  
        {property: 'og:description', content: this.emojiService.colonsToNative(this.content.info.desc || '')},
        {property: 'og:site_name', content: 'SWAN Beauty Community'},
        {property: 'twitter:image:alt', content: `${this.content.author.displayName} Profile Image`},
      ]);
    }
    )
  }
  ngOnDestroy(): void {
    this.interval && clearInterval(this.interval);
  }

  ngAfterViewInit(): void {
    if(this.playback){
      this.initMuxPlayer();
    }
    this.ref.markForCheck();
  }

  initMuxPlayer() {

    this.muxVideo.nativeElement.setAttribute('stream-type', 'on-demand');
    this.muxVideo.nativeElement.setAttribute('thumbnail-token', this.content.splashScreen.split('?')[1].substring(6));
    this.muxVideo.nativeElement.setAttribute('playback-id', this.id);
    this.muxVideo.nativeElement.setAttribute('playback-token', this.token);
    this.muxVideo.nativeElement.setAttribute('storyboard-token', this.playback.storyBoard.split('?')[1].substring(6));
    this.muxVideo.nativeElement.setAttribute('metadata-video-title', this.content.info.name);
    this.muxVideo.nativeElement.setAttribute('metadata-viewer-user-id', "anonymous User");

    if(typeof document !== "undefined" ){
      document.addEventListener('openApp', ()=>{
        openApp();
      })
    }
  }

    getImageUrl(): string {
        //https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+profile.imageId
        return this.configService.getBaseUrl() + '/profile-service/image/download?imageId=' + this.content?.author?.imageId;
    }

  getStoreLink = openApp;

}
