import { Component, Inject, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { openApp } from '../layout/layout.component';
import { RESPONSE } from 'share/src/app/core/services/server/server.token';
import { Response } from 'express';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  platformId = inject(PLATFORM_ID);

  constructor(
    @Optional() @Inject(RESPONSE) private response: Response
  ) {
    if(isPlatformServer(this.platformId)){
      this.response?.status(404);
    }
  }

  ngOnInit(): void {
    if(isPlatformServer(this.platformId)){
      this.response?.status(404);
    }
  }
  getStoreLink = openApp;
}
