<ng-template>
  <div class="carousel-slide" [style.background-image]="image" [style.background-size]="bgSize">
    <div class="carousel-slide-content">
      <ng-content></ng-content>
    </div>
    @if (!hideOverlay) {
      <div
        class="carousel-slide-overlay"
        [style.background-color]="overlayColor"
      ></div>
    }
  </div>
</ng-template>
