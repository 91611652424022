
@switch (style) {
  @case ('close') {
    <button mat-icon-button
      (click)="routerLink === undefined && close()"
      [routerLink]="routerLink"
      class="close w-10 h-8 z-10 rounded-full flex"
      title="{{'BUTTON.CLOSE' | transloco }}">
      <mat-icon [svgIcon]="closeSvgIcon" class="text-white"></mat-icon>
    </button>
  }
  @case ('back') {
    <button mat-icon-button
      [routerLink]="routerLink"
      title="{{'BUTTON.BACK' | transloco }}"
      (click)="routerLink === undefined && close()">
      <mat-icon [svgIcon]="backSvgIcon" class="text-black"></mat-icon>
    </button>
  }
}
