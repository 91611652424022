import { Component, Inject, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { Response } from 'express';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile, PublicProfile } from '@swan/lib/domain';
import { openApp } from '../shared/layout/layout.component';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Meta, Title } from '@angular/platform-browser';
import { RESPONSE } from '../../core/services/server/server.token';
import { isPlatformServer } from '@angular/common';
import { EmojiService } from '@yukawa/chain-base-angular-comp/shared';

export const LevelColorMap: Map<number, string> = new Map([
  [-1, '#9e9e9e'],
  [1, '#F2D25F'],
  [2, '#FFCC94'],
  [3, '#F2BC9F'],
  [4, '#FCC0C0'],
  [5, '#CF99B0'],
  [6, '#B7A9DE'],
  [7, '#B0C7E3'],
])

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile!: PublicProfile 
  configService: ConfigService = inject(ConfigService);
  emojiService: EmojiService = inject(EmojiService);
  _platformId: Object = inject(PLATFORM_ID);  

  constructor(
    private _route: ActivatedRoute, 
    private _router: Router, 
    private meta: Meta,
    private title: Title,
    @Optional() @Inject(RESPONSE) private response: Response // is NOT injected on DEV (using `ng serve`) build app and run with node /.../server/server.mjs
    ) { 
    this.profile = _route.snapshot.data['profile'];
    if(!this.profile){
      if(isPlatformServer(this._platformId) && this.response){
        this.response.redirect(301, '/404');
        this.response.end();
      }else{
        this._router.navigate(['/404']);
      }
    } 
    this.title.setTitle( `${this.emojiService.colonsToNative(this.profile.displayName || '')} - SWAN Beauty Community`);
    this.meta.addTags([
      {property: 'og:title', content: this.emojiService.colonsToNative(this.profile.displayName || '')},
      {property: 'og:type', content: 'profile'},
      {property: 'og:image', content: this.getImageUrl(this.profile.imageId || '')},
      {property: 'og:url', content: this.configService.getBaseUrl()},
      {name: 'twitter:card', content: 'summary_large_image'},

      {property: 'og:description', content: this.emojiService.colonsToNative(this.profile.description || '')},
      {property: 'og:site_name', content: 'SWAN Beauty Community'},
      {property: 'twitter:image:alt', content: `${this.profile.displayName} Profile Image`},
    ]);
  }

  ngOnInit(): void {
  }

  levelColor():string{
    return LevelColorMap.get(this.profile.level || -1) || '#9e9e9e'
  }
  getImageUrl(imageId:string): string {
    if(imageId === ''){
      return this.configService.getBaseUrl() + '/assets/icons/swan-icon.svg'
    }
    //https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+profile.imageId
    return this.configService.getBaseUrl() + '/profile-service/image/download?imageId=' + imageId
  }

  getStoreLink = openApp;
}
